<template>
  <div class="container">
    <h2>Términos y condiciones</h2>
    <p>Aquí puedes acceder al detalle de la política de tratamiento de datos personales y términios y condiciones de uso</p>

    <section>
      <b-row>
        <b-col lg="6">
          <CCard
            @click="goto('terms')">
            <CCardBody>
              <h5>Términos y condiciones</h5>
            </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="6">
          <CCard
            @click="goto('personalData')">
            <CCardBody>
              <h5>Política de tratamiento de datos personales</h5>
            </CCardBody>
          </CCard>
        </b-col>
        
      </b-row>
    </section>
  </div>
</template>

<script>
  export default {
    name: "TermsDashboard",
    methods: {
      goto(to) {
        if(to == 'terms'){
          this.$router.push({path: 'terms'})
        } else if(to == 'personalData'){
          this.$router.push({path: 'personaldata'})
        }
      }
    }

  }
</script>

<style scoped>

</style>